import React, {useState} from 'react';
import {useTranslation} from 'next-i18next';
import Link from 'next/link'
import MobileMenu from './mobileMenu/index';
import {config} from '../../config-merchant';
import {connect} from 'react-redux';
import {updateCurrency} from '../../store/action'
import {Customer, ShopState} from '../../interfaces/store-interfaces';
import {getCategories} from '../../services/catalog-services';
import {Category} from '../../interfaces/catalog-interfaces';
import {I18n, withTranslation} from 'next-i18next';
import Head from 'next/head';
import _variables from '../../styles/global.module.scss'
import {getTopHeader} from "../../services/merchant-services";

export type HeaderProps = {
    categories: Category[];
    topHeader: string;
}

export const getComponentStaticProps = async (locale) => {
    try {
        const categoriesList = await getCategories((locale).toUpperCase());
        const topHeader = await getTopHeader((locale).toUpperCase());

        return {
            categories: categoriesList['data'],
            topHeader: topHeader['data']
        };
    } catch (e) {
        return {
            categories: [],
            topHeader: ""
        };
    }
}

function HeaderAppyShop(props: { activepage?: string, activeCategory?: number, searchQuery?: string, currentCurrency: string, headerProps: HeaderProps, i18n: I18n, updateCurrency: any, nbItemsOnCart: number, customer: Customer }) {

    const {t} = useTranslation('common');
    const [isActive, setActive] = useState(false);
    const toggleMobileMenu = () => {
        setActive(!isActive);
    };

    return (
        <>
            <Head>
                <meta name="theme-color" content={_variables.mainColor}/>
            </Head>
            <header>
                {props.headerProps.topHeader.length > 0 && <div className={"headerband headerband-open"}>
                    {props.headerProps.topHeader}
                </div>}

                {/* <!--(Fixed) Header navigation bar --> */}
                <nav>
                    {/* <!--(Left) logotype--> */}
                    <div className="nav-logotype">
                        <Link href='/home'>
                            <img src="/assets/svg/logo-header.svg" alt="logo-header"/>
                        </Link>
                    </div>

                    {/* <!--(Right) Icons account/cart--> */}
                    <div className="nav-access">

                        {/* <!--Icon my Account--> */}
                        <Link href="/my-account">
                            <div className="config-icon">
                                {(props.customer === undefined || props.customer === null) &&
                                    <svg className="svg-account" xmlns="http://www.w3.org/2000/svg" width="23.333"
                                         height="35"
                                         viewBox="0 0 23.333 35">
                                        <defs>
                                            <style>{'.ic-account-a,.ic-account-b{fill:#2a2a2a;}.ic-account-b{fill-rule:evenodd;}.ic-account-a{fill-rule:evenodd;}.ic-account-c{fill:#e33d63;}.ic-account-d{fill:#fff;}'}</style>
                                        </defs>
                                        <g transform="translate(-727.195 -400.236)">
                                            <path className="ic-account-a"
                                                  d="M11.778,18.556A7.778,7.778,0,1,0,4,10.778,7.778,7.778,0,0,0,11.778,18.556Zm0-3.889a3.889,3.889,0,1,0-3.889-3.889A3.889,3.889,0,0,0,11.778,14.667Z"
                                                  transform="translate(727.084 397.236)"/>
                                            <path className="ic-account-b"
                                                  d="M19.5,15.889a1.944,1.944,0,0,1,1.944,1.944V29.5h3.889V17.833A5.833,5.833,0,0,0,19.5,12H7.833A5.833,5.833,0,0,0,2,17.833V29.5H5.889V17.833a1.944,1.944,0,0,1,1.944-1.944Z"
                                                  transform="translate(725.195 405.736)"/>
                                        </g>
                                    </svg>}
                                {!(props.customer === undefined || props.customer === null) &&
                                    <svg className="svg-account" xmlns="http://www.w3.org/2000/svg" width="31.971"
                                         height="35.25"
                                         viewBox="0 0 31.971 35.25">
                                        <defs>
                                            <style>{'.ic-account-a,.ic-account-b{fill:#2a2a2a;}.ic-account-b{fill-rule:evenodd;}.ic-account-a{fill-rule:evenodd;}.ic-account-c{fill:#e33d63;}.ic-account-d{fill:#fff;}'}</style>
                                        </defs>
                                        <g transform="translate(-1182.029 -178.75)">
                                            <g transform="translate(454.833 -221.486)">
                                                <path className="ic-account-a"
                                                      d="M11.778,18.556A7.778,7.778,0,1,0,4,10.778,7.778,7.778,0,0,0,11.778,18.556Zm0-3.889a3.889,3.889,0,1,0-3.889-3.889A3.889,3.889,0,0,0,11.778,14.667Z"
                                                      transform="translate(727.084 397.236)"/>
                                                <path className="ic-account-b"
                                                      d="M19.5,15.889a1.944,1.944,0,0,1,1.944,1.944V29.5h3.889V17.833A5.833,5.833,0,0,0,19.5,12H7.833A5.833,5.833,0,0,0,2,17.833V29.5H5.889V17.833a1.944,1.944,0,0,1,1.944-1.944Z"
                                                      transform="translate(725.195 405.736)"/>
                                            </g>
                                            <circle className="ic-account-c" cx="10" cy="10" r="10"
                                                    transform="translate(1194 194)"/>
                                            <path className="ic-account-d"
                                                  d="M12.965,20.806a1.5,1.5,0,0,1-1.061-.439L7.939,16.4a1.5,1.5,0,0,1,2.121-2.121l2.9,2.9,6.312-6.319a1.5,1.5,0,1,1,2.122,2.12l-7.373,7.381A1.493,1.493,0,0,1,12.965,20.806Z"
                                                  transform="translate(1190.059 189.123)"/>
                                        </g>
                                    </svg>}
                            </div>
                        </Link>

                        {/* <!--Icon Shopping cart--> */}
                        {
                            (config.menu.some(item => item.path === 'catalog') || config.footerLink.some(item => item.url === '/catalog')) &&
                            <Link href="/cart">
                                <div className="config-icon">
                                    <svg className="svg-shopping" xmlns="http://www.w3.org/2000/svg" width="35"
                                         height="35" viewBox="0 0 35 35">
                                        <defs>
                                            <style>{'.ic-shopping-a{fill:#2a2a2a;fill-rule:evenodd;}'}</style>
                                        </defs>
                                        <path className="ic-shopping-a"
                                              d="M7.25,5.5h24.5A1.75,1.75,0,0,1,33.5,7.25v24.5a1.75,1.75,0,0,1-1.75,1.75H7.25A1.75,1.75,0,0,1,5.5,31.75V7.25A1.75,1.75,0,0,1,7.25,5.5ZM2,7.25A5.25,5.25,0,0,1,7.25,2h24.5A5.25,5.25,0,0,1,37,7.25v24.5A5.25,5.25,0,0,1,31.75,37H7.25A5.25,5.25,0,0,1,2,31.75ZM19.5,19.5c-4.832,0-8.75-4.7-8.75-10.5h3.5c0,4.491,2.921,7,5.25,7s5.25-2.509,5.25-7h3.5C28.25,14.8,24.332,19.5,19.5,19.5Z"
                                              transform="translate(-2 -2)"/>
                                    </svg>
                                    <div className="shopping-count">{props.nbItemsOnCart}</div>
                                </div>
                            </Link>
                        }

                        {/* <!--Icon Nav mobile--> */}
                        <div>
                            <svg
                                onClick={toggleMobileMenu}
                                className="open-menu"
                                xmlns="http://www.w3.org/2000/svg"
                                width="41" height="30" viewBox="0 0 41 30">
                                <defs>
                                    <clipPath id="clip-path">
                                        <rect width="41" height="30" fill="none"/>
                                    </clipPath>
                                </defs>
                                <g id="Repeat_Grid_2" data-name="Repeat Grid 2" clipPath="url(#clip-path)">
                                    <g transform="translate(-344 -62)">
                                        <rect id="Rectangle_3" data-name="Rectangle 3" width="41" height="5"
                                              transform="translate(344 62)" fill={_variables.colorBase}/>
                                    </g>
                                    <g transform="translate(-344 -51)">
                                        <rect id="Rectangle_3-2" data-name="Rectangle 3" width="41" height="5"
                                              transform="translate(344 62)" fill={_variables.colorBase}/>
                                    </g>
                                    <g transform="translate(-344 -40)">
                                        <rect id="Rectangle_3-3" data-name="Rectangle 3" width="41" height="5"
                                              transform="translate(344 62)" fill={_variables.colorBase}/>
                                    </g>
                                </g>
                            </svg>
                        </div>
                    </div>

                    {/* <!--(Center) Menu navigation--> */}
                    <div className="nav-menu">
                        <ul>
                            {config.menu.map(item => {
                                if (item.external) {
                                    return <li>
                                        <a href={item.path} target={"_blank"} rel={"noreferrer"}>
                                            {t(item.name)}
                                        </a>
                                    </li>
                                }
                                if (item.path !== 'catalog') {
                                    if (item.submenu.length === 0) {
                                        return <Link key={item.path} href={'/' + item.path}>
                                            <li className={props.activepage === item.path ? "active" : ""}>{t(item.name)}</li>
                                        </Link>
                                    } else {
                                        return <Link key={item.path} href={'/' + item.path}>
                                            <li className={props.activepage === item.path ? "active" : ""}>
                                                {t(item.name)}
                                                <img src="/assets/svg/arrow-down.svg" alt="display-submenu"/>
                                                <ul className="submenu">
                                                    {[1, 2, 3, 4].map((_, index) => {
                                                        const arraySliced = item.submenu.slice(index * 5, index * 5 + 5);
                                                        if (arraySliced.length !== 0) {
                                                            return <div key={index}>
                                                                {arraySliced.map(subitem => <Link key={subitem.path}
                                                                                                  href={'/' + item.path + '/' + subitem.path}>
                                                                    <li>{t(subitem.name)}</li>
                                                                </Link>)}
                                                            </div>
                                                        }
                                                    })}
                                                </ul>
                                            </li>
                                        </Link>
                                    }
                                } else {
                                    return <Link href="/catalog" key={item.path}>
                                        <li className={props.activepage === "catalog" ? "active" : ""}>{t(config.menu.find(item => item.path === 'catalog').name)}<img
                                            src="/assets/svg/arrow-down.svg" alt="display-submenu"/>
                                            <ul className="submenu">
                                                {[1, 2, 3, 4].map((_, index) => {
                                                    const arraySliced = props.headerProps.categories.slice(index * 5, index * 5 + 5);
                                                    if (arraySliced.length !== 0) {
                                                        return <div key={index}>
                                                            {index === 0 && <Link href="/catalog">
                                                                <li className={(props.activeCategory === 0 && props.searchQuery === undefined) ? "active" : ""}>{t('CATALOG_ALL')}</li>
                                                            </Link>}
                                                            {arraySliced.map(category => <Link key={category.categoryId}
                                                                                               href={`/catalog?category=${category.categoryId}`}>
                                                                <li className={props.activeCategory === category.categoryId ? "active" : ""}>{category.name}</li>
                                                            </Link>)}
                                                        </div>
                                                    }
                                                })}
                                            </ul>
                                        </li>
                                    </Link>
                                }
                            })}
                        </ul>
                    </div>
                </nav>

                {/* <!--Logo bar--> */}
                {/* <!--<div className="logotype">--> */}
                {/* <!--<Link href='/home'><img src="/assets/svg/logo-header.svg" alt="logo-header" /></Link>--> */}
                {/* <p className="slogan">La vente en ligne simple et efficace</p> */}
                {/* <!--</div>--> */}

                {/* <!--Nav mobile--> */}
                <MobileMenu
                    categories={props.headerProps.categories}
                    isActive={isActive}
                    toggleMenu={toggleMobileMenu}/>

            </header>
        </>
    );
}

const mapState = (state: ShopState) => ({
    currentCurrency: state.currentCurrency,
    nbItemsOnCart: state.nbItemsOnCart,
    customer: state.customer
})

const mapDispatch = (dispatch) => {
    return {
        updateCurrency: (currency) => dispatch(updateCurrency(currency))
    }
}

export default connect(mapState, mapDispatch)(withTranslation('common')(HeaderAppyShop))
